import { template as template_3694e6fd0af847e7acd5fb2f29f451b9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3694e6fd0af847e7acd5fb2f29f451b9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
