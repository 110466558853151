import { template as template_a09bfc3f72204f8a90e4bb1f33a67c23 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_a09bfc3f72204f8a90e4bb1f33a67c23(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
