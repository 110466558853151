import { template as template_73be9d3c93094c04a7cf63d722585811 } from "@ember/template-compiler";
const WelcomeHeader = template_73be9d3c93094c04a7cf63d722585811(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
